import { ActionDataWidgets } from '../types'
import { BigImageFormFields } from './types'
import { FormProps } from '../components/Layout'
import { LinkProvider } from '@dtx-company/inter-app/src/constants/linkTypes'
import { MaskType } from '@app/common/src/components/ImageEditor/ImageEditor.types'
import { UseFormMethods, useForm } from 'react-hook-form-deprecated'
import { defaultWidgetLink } from '../../../constants'
import { getBigImagePlaceholder } from './utils'
import { getValidatedActionData } from '../typeUtils'
import { useDeleteLinkWithAutosave } from '../deleteUtils'
import { useEffect, useMemo } from 'react'
import { useSetValueWithPreview } from '../utils'
import { useSubmitWidget } from '../submitUtils'
import { v4 as uuid } from 'uuid'

type BigImageFormState = {
  formTitle: string
  formValues: {
    image: string
    maskType: MaskType
  }
  imageErrorMessage?: string
  setValue: UseFormMethods['setValue']
  handleSubmit: () => void
  deleteImageLink: () => void
}

export const useBigImageFormState = ({
  curr,
  order,
  handleClose
}: Pick<FormProps, 'curr' | 'order' | 'handleClose'>): BigImageFormState => {
  const id = curr ? curr.id : uuid()
  const widgetType: ActionDataWidgets = 'bigImage'

  const actionData = getValidatedActionData<'bigImage'>(curr?.actionData, 'maskType')
  const submitWidget = useSubmitWidget()
  const {
    register,
    setValue: setFormValue,
    handleSubmit,
    watch,
    formState
  } = useForm({
    defaultValues: {
      image: curr?.thumbNailImgUrl ?? '',
      maskType: actionData?.maskType ?? MaskType.RECTANGLE
    }
  })

  const formValues = watch()
  const bigImagePlaceholderImg = getBigImagePlaceholder(formValues.maskType)
  const imageErrorMessage = formValues[BigImageFormFields.image]
    ? undefined
    : formState.errors?.[BigImageFormFields.image]?.message
  const deleteLink = useDeleteLinkWithAutosave()
  const onSubmit = async (): Promise<void> => {
    await submitWidget({
      curr,
      image: formValues.image,
      widgetType,
      actionData: {
        maskType: formValues.maskType
      },
      fields: {
        id,
        order
      }
    })
    handleClose()
  }

  const deleteImageLink = (): void => {
    deleteLink({
      linkId: id,
      linkOrder: order
    })

    handleClose()
  }

  const previewLink = useMemo(
    () => ({
      ...defaultWidgetLink,
      id,
      order,
      thumbNailImgUrl: formValues.image || bigImagePlaceholderImg,
      provider: LinkProvider.WIDGET,
      type: widgetType,
      actionData: {
        maskType: formValues.maskType
      }
    }),
    // TODO: resolve this rule of hooks error - issue tracked in https://app.shortcut.com/flowcode/story/41455/resolve-rule-of-hooks-error-that-were-commented-out
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formValues.image, formValues.maskType]
  )

  const { setValue } = useSetValueWithPreview(previewLink, setFormValue)

  useEffect(() => {
    register(BigImageFormFields.maskType)
    register(BigImageFormFields.image, { required: 'Please select an image' })
  }, [register])

  return {
    formTitle: `${curr ? 'Edit this' : 'Add an'} Image`,
    formValues: formValues,
    imageErrorMessage,
    setValue,
    handleSubmit: handleSubmit(onSubmit),
    deleteImageLink
  }
}
