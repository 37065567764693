import * as icons from '../../../assets/icons'
import { BigImageFormFields } from './types'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { ChangeEvent, FC, useEffect } from 'react'
import { FileUploadInput } from '../../util/SettingsUtil'
import { FlowpageProps } from '../types/FlowpageProps'
import { FormFields } from '../types'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { SecondaryButton } from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { Section } from '@dtx-company/shared-components/src/components/atoms/Section/index'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { UseFormMethods } from 'react-hook-form-deprecated'
import { Yield } from '@dtx-company/shared-components/src'
import { exceedsMaxFileSize } from '@dtx-company/shared-components/src/components/organisms/ImageEdit/utils'
import { getBigImageStyle } from './utils'
import { sendErrorNotification } from '@dtx-company/inter-app/src/utils/notifications'
import { trashCanRed } from '@app/code/src/assets'
import { useLinkImageEditModalState } from '../components/LinkImageEditModal/hooks'

/**
 * Component that renders the actual image in the flowpage, preview and manager
 * for the Big Image section
 */
export const BigImageComponent: FC<FlowpageProps> = ({
  link,
  isEditLinkPreview
}: FlowpageProps) => {
  const { actionData, thumbNailImgUrl } = link
  const { borderRadius, width, height } = getBigImageStyle(actionData?.maskType)
  return (
    <Box justifyContent="center" width="100%">
      <Image
        src={thumbNailImgUrl as string}
        width={isEditLinkPreview ? width : '100%'}
        height={isEditLinkPreview ? height : 'initial'}
        borderRadius={borderRadius}
      />
    </Box>
  )
}

interface BigImagePickerAndEditorProps {
  id: string
  imageUrl?: string
  replaceButtonCTA?: string
  deleteButtonCTA?: string
  setValue: UseFormMethods['setValue']
  imageFormFieldName?: string
  maskTypeFormFieldName?: string
  showImageSizeWarningToast?: boolean
  setImageSizeWarning?: (val: boolean) => void
  addCopy?: string
  deleteImage?: boolean
}

export const BigImagePickerAndEditor: FC<BigImagePickerAndEditorProps> = ({
  id,
  imageUrl,
  setValue,
  addCopy = 'Add Image',
  imageFormFieldName = FormFields.IMAGE,
  maskTypeFormFieldName = BigImageFormFields.maskType,
  deleteImage = false
}) => {
  const { resultImageUrl, maskType, openImageEditModal } = useLinkImageEditModalState()

  const handleStagingImage = (e: ChangeEvent<HTMLInputElement>): void => {
    const stagedImage = e.target.files?.[0]
    if (stagedImage) {
      if (exceedsMaxFileSize(stagedImage.size)) {
        sendErrorNotification('Image is too large to upload (size limit 10MB)')
      } else {
        openImageEditModal({
          sourceImageUrl: URL.createObjectURL(stagedImage),
          maskType
        })
      }
    }
  }

  useEffect(() => {
    setValue(imageFormFieldName, resultImageUrl ? resultImageUrl : undefined)
    setValue(maskTypeFormFieldName, maskType)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maskType, resultImageUrl])

  const handleEditImage = (): void => {
    if (imageUrl) {
      openImageEditModal({
        sourceImageUrl: imageUrl,
        maskType
      })
    }
  }

  const { width, height, borderRadius } = getBigImageStyle(maskType)

  return (
    <>
      <Box display="block">
        <FileUploadInput handleUpload={handleStagingImage} id={id} position="relative" width="100%">
          <Box justifyContent="center">
            {imageUrl ? (
              <Image
                src={imageUrl || ''}
                width={width}
                height={height}
                borderRadius={borderRadius}
              />
            ) : (
              <Section
                width={width}
                height={height}
                borderRadius={borderRadius}
                bg="primary.grey"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Image src={icons.photoOutlinedWhite} width="25px" />
                <Spacer mb="8px" />
                <Text variant="input/label" color="primary.white">
                  {addCopy}
                </Text>
              </Section>
            )}
          </Box>
        </FileUploadInput>
        <Spacer mb="8px" />
        <Box display="block"></Box>
        {imageUrl && (
          <Box>
            {deleteImage && (
              <DeleteImageButton onClick={() => setValue(imageFormFieldName, null)} />
            )}
            <EditImageButton onClick={handleEditImage} />
          </Box>
        )}
      </Box>
    </>
  )
}

const EditImageButton: FC<{ onClick: () => void }> = ({ onClick }) => (
  <SecondaryButton
    height="40px"
    width="100%"
    borderColor={'primary.flowBlue'}
    onClick={onClick}
    mb="8px"
    type="button"
  >
    <Image src={icons.pencilOutlineBlue} width="20px" height="20px" />
    <Spacer mr="8px" />
    <Text variant="button/general" color="primary.flowBlue" data-testid="delete-link-btn">
      Edit Image
    </Text>
  </SecondaryButton>
)

const DeleteImageButton: FC<{ onClick: () => void }> = ({ onClick }) => (
  <SecondaryButton
    height="40px"
    width="100%"
    borderColor={'status.errorDark'}
    onClick={onClick}
    mb="8px"
    mr="8px"
    type="button"
  >
    <Image src={trashCanRed} width="20px" height="20px" />
    <Spacer mr="8px" />
    <Text variant="button/general" color="status.errorDark">
      Delete Image
    </Text>
  </SecondaryButton>
)

export const ImageSizeWarning: FC = () => {
  return (
    <Box alignItems="center">
      <Yield height="60px" width="60px" color="#e1cb00" />
      <Text ml="12px" fontSize="13px" color="status.warningDark">
        Warning: This photo is low resolution and may come out pixelated on larger devices
      </Text>
    </Box>
  )
}
