import * as icons from '../../../assets/icons'
import { ActivateButton } from '../components/Manager/ActivateButton'
import { BigImageComponent, BigImagePickerAndEditor } from './subcomponents'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { DeleteModeDrawer } from '../components/Manager/DeleteModeDrawer'
import { Divider } from '@dtx-company/shared-components/src/components/atoms/Divider/index'
import { FC } from 'react'
import { FlowpageProps } from '../types/FlowpageProps'
import { FormProps, StyledFormV2 as StyledForm } from '../components/Layout'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { InlineManagerCard, ManagerProps } from '../components/Manager/Manager'
import { LinkEditSection } from '../../../components/profile/Links/LinkEditSection'
import { LockedTemplateField } from '../../FlowpageTemplates/LockedTemplateField/LockedTemplateField'
import { ManagerSettings } from '../components/Manager/ManagerSettings'
import { MaskType } from '@app/common/src/components/ImageEditor/ImageEditor.types'
import {
  PrimaryButton,
  SecondaryButton
} from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { trashCanRed } from '@app/code/src/assets'
import { useBigImageFormState } from './hooks'
import { useManagerSettings } from '../components/Manager/useManagerSettings'
import capitalize from 'lodash/capitalize'

export const maskTypeOptions = [
  { value: MaskType.CIRCLE, title: capitalize(MaskType.CIRCLE) },
  { value: MaskType.SQUARE, title: capitalize(MaskType.SQUARE) },
  { value: MaskType.RECTANGLE, title: capitalize(MaskType.RECTANGLE) }
]

export type BigImageFormProps = Pick<FormProps, 'curr' | 'handleClose' | 'order'>

export const Form: FC<BigImageFormProps> = ({ curr, order, handleClose }) => {
  const { formTitle, formValues, handleSubmit, setValue, deleteImageLink, imageErrorMessage } =
    useBigImageFormState({
      curr,
      order,
      handleClose
    })

  return (
    <StyledForm onSubmit={handleSubmit}>
      <Box width="100%" display={['none', 'none', 'flex']}>
        <Image src={icons.photoOutlined} height="20px" width="20px" />
        <Spacer mr="8px" />
        <Text color="primary.black" variant="button/general">
          {formTitle}
        </Text>
      </Box>
      <Divider display={['none', 'none', 'block']} />

      <BigImagePickerAndEditor
        imageUrl={formValues.image}
        setValue={setValue}
        id="big-image-picker"
      />
      {imageErrorMessage && (
        <Text mb="8px" color="status.errorDark">
          {imageErrorMessage}
        </Text>
      )}
      <Box justifyContent="space-between">
        <SecondaryButton
          height="40px"
          width="49%"
          borderColor={'status.errorDark'}
          onClick={deleteImageLink}
          type="button"
        >
          <Image src={trashCanRed} />
          <Spacer mr="8px" />
          <Text variant="button/general" color="status.errorDark" data-testid="delete-link-btn">
            Delete Image
          </Text>
        </SecondaryButton>
        <PrimaryButton width="49%" height="40px" type="submit" label="Save Image" />
      </Box>
    </StyledForm>
  )
}

export const Flowpage: FC<FlowpageProps> = ({ link }: FlowpageProps) => (
  <BigImageComponent link={link} />
)

/**
 * Special Manager for the BigImage link (Image Section)
 * given the layout and preview of this section in the page
 * is quite different from DefaultManager (no title or description)
 * and need the image to occupy most of the space.
 */
export const Manager: FC<ManagerProps> = ({ link, handle, editWidget }: ManagerProps) => {
  const {
    onDeleteLink,
    deleteLinkModeActive,
    toggleDeleteMode,
    showEditLink,
    setShowEditLink,
    toggleActivate,
    isActive,
    managerContainerStyles,
    hasViewOnlyAccess,
    toggleEditMode,
    childOnClick,
    isTemplateConfigLocked
  } = useManagerSettings(link, editWidget)

  const paddingStyles = showEditLink ? '0' : '16px 0 0 0'
  const rightMargin = hasViewOnlyAccess ? '52px' : '12px'

  return (
    <InlineManagerCard
      showEditLink={showEditLink}
      padding={paddingStyles}
      viewOnly={hasViewOnlyAccess || isTemplateConfigLocked}
      {...managerContainerStyles.deactivatedContainerStyles}
    >
      {showEditLink ? (
        <LinkEditSection handleClose={() => setShowEditLink(false)} />
      ) : (
        <Box width="100%" alignItems="center">
          {!isTemplateConfigLocked && (
            <ManagerSettings
              onClickEdit={toggleEditMode}
              onClickDelete={toggleDeleteMode}
              disabled={hasViewOnlyAccess}
              deleteModeActive={deleteLinkModeActive}
              viewOnly={hasViewOnlyAccess}
            />
          )}
          {isTemplateConfigLocked && <LockedTemplateField />}
          <Spacer mr="16px" />
          <Box width="100%" justifyContent="center" onClick={childOnClick}>
            <BigImageComponent isEditLinkPreview link={link} />
          </Box>
          <>
            <ActivateButton
              viewOnly={hasViewOnlyAccess || isTemplateConfigLocked}
              toggleActivated={toggleActivate}
              activated={isActive}
            />
            <Spacer mr="12px" />
            {!hasViewOnlyAccess && handle}
            <Spacer mr={rightMargin} />
          </>
        </Box>
      )}
      <Spacer mb="16px" />
      {!showEditLink && (
        <DeleteModeDrawer
          deleteModeActive={deleteLinkModeActive}
          toggleDeleteMode={toggleDeleteMode}
          onDelete={onDeleteLink}
        />
      )}
    </InlineManagerCard>
  )
}
