import { MaskType } from '@app/common/src/components/ImageEditor/ImageEditor.types'

type BigImageStyle = {
  width?: React.CSSProperties['width']
  height?: React.CSSProperties['height']
  borderRadius?: React.CSSProperties['borderRadius']
}

export const getCRMImageStyle = (maskType: MaskType): BigImageStyle => {
  switch (maskType) {
    case MaskType.SQUARE:
      return { width: 70, height: 70, borderRadius: 8 } // 70x70 has always been the default bio avatar size
    case MaskType.CIRCLE:
      return { width: 70, height: 70, borderRadius: '50%' }
    case MaskType.RECTANGLE:
      return { width: 125, height: 70, borderRadius: 8 } // 16:9 aspect ratio
    default:
      return { width: '100%', borderRadius: 8 }
  }
}

export const getBigImageStyle = (maskType: MaskType): BigImageStyle => {
  switch (maskType) {
    case MaskType.SQUARE:
      return { width: 140, height: 140, borderRadius: 8 } // 140x140 has always been the default bio avatar size
    case MaskType.CIRCLE:
      return { width: 140, height: 140, borderRadius: '50%' }
    case MaskType.RECTANGLE:
      return { width: 249, height: 140, borderRadius: 8 } // 16:9 aspect ratio
    default:
      return { width: '100%', borderRadius: 8 }
  }
}

export const getBigImagePlaceholder = (maskType: MaskType): string => {
  switch (maskType) {
    case MaskType.SQUARE:
    case MaskType.CIRCLE:
      return '/static/placeholders/BigImage-square-placeholder.png'
    case MaskType.RECTANGLE:
    default:
      return '/static/placeholders/BigImage-rectangle-placeholder.png'
  }
}
